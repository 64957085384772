import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function Spinner() {
  return (
    <div className="minh-100 center">
      <div className="spinner">
        <img className="spinnerima" src="/images/logo_cut.jpeg" />
      </div>
    </div>
  );
}

export default Spinner;
