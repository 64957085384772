import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaQuestionCircle } from "react-icons/fa";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ModalCustom from "../components/ModalCustom";

const popover2 = (
  <Popover id="popover-basic2">
    <Popover.Header as="h3">Correo electrónico</Popover.Header>
    <Popover.Body>
      Escribe tu correo electrónico personal, la mayoría de personas con un
      teléfono celular tiene uno. Este sera necesaria para iniciar sesión
      posteriormente.
    </Popover.Body>
  </Popover>
);

function RecoveryPassword() {
  const { register, handleSubmit } = useForm();
  let history = useNavigate();
  const [response, setResponse] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const ir_a_login = () => history("/");

  const onSubmit = (data) => {
    setLoading(true);
    const requestMetadata = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    fetch(process.env.REACT_APP_API_WISP+"users/recovery_password", requestMetadata)
      .then(async (response) => {
        let data = await response.json();
        console.log("Success", data);
        setResponse(data);
        setLoading(false);
          if (data.code === 200) {
            history("/verificationp?email="+data.data.email);
          }else if (data.code !== 295) {
            setResponse({
              code: 500,
              data: "Upss. Hubo un problema en el servidor, estamos trabajando en solucionarlo. Intente mas tarde por favor",
            });
            handleShow();
          }
      })
      .catch((error) => {
        setLoading(false);
          console.error("Error:", error);
          setResponse({
            code: 500,
            data: "Upss. No se pudo conectar con el servidor, valide su conexion a internet. E intentelo de nuevo",
          });
          handleShow();
      });
  };

  return (
    <Container>
      <Row className="justify-content-center align-items-center minh-100">
        <Col sm="11" md="10" lg="5" xl="4" xxl="4">
          <div className="card card-inverse p-4">
            <div className="card-block">
              <Image src="/images/logo.jpeg" fluid></Image>
              <hr />
              <Form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup className="mt-2" hasValidation>
                  <Form.Control
                    type="email"
                    {...register("email")}
                    placeholder="Escriba su correo"
                    required
                    isInvalid={response?.code === 295}
                    isValid={response === null && response.code !== 295}
                  />
                  <OverlayTrigger
                    trigger="focus"
                    placement="left"
                    overlay={popover2}
                  >
                    <Button className="p-0" variant="outline-primary">
                      <FaQuestionCircle size={"1.8em"} />
                    </Button>
                  </OverlayTrigger>
                  {response?.code === 295 && (
                    <Form.Control.Feedback type="invalid">
                      {response.data}
                    </Form.Control.Feedback>
                  )}
                </InputGroup>
                <Button
                  type="submit"
                  className="w-100 mt-2"
                  disabled={isLoading}
                >
                  {isLoading ? "Enviando código…" : "Enviar Código"}
                </Button>
                <p>
                  <Link to="/">Iniciar sesión</Link>
                </p>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      <ModalCustom
        show={show}
        title={"Información"}
        body={response.data}
        action={response?.code === 200 ? ir_a_login : handleClose }
        action_text={response?.code === 200 ? "Iniciar sesión" : "ACEPTAR"}
        handleClose={handleClose}
      />
    </Container>
  );
}

export default RecoveryPassword;
