import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ModalCustom from "../components/ModalCustom";
import { useEffect, useState } from "react";

function Login() {
  const { register, handleSubmit } = useForm();
  const [response, setResponse] = useState([]);
  let history = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const setCustomer = (data) => {
    localStorage.setItem("customer", data);
  };

  const getcustomer = () => {
    return localStorage.getItem("customer") === null
      ? 0
      : localStorage.getItem("customer");
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const ir_a_login = () => history("/");

  const onSubmit = (data) => {
    setLoading(true);
    const requestMetadata = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    fetch(process.env.REACT_APP_API_WISP + "users/login", requestMetadata)
      .then(async (response) => {
        let data = await response.json();
        console.log("Success", data);
        setResponse(data);
        setLoading(false);
        if (data.code === 200) {
          setCustomer(data.data.customer);
          history("/notice");
        } else if(data.code !== 294 || data.code !== 293){
        }else{
          setResponse({
            code: 500,
            data: "Upss. Hubo un problema en el servidor, estamos trabajando en solucionarlo. Intente mas tarde por favor",
          });
          handleShow();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
        setResponse({
          code: 500,
          data: "Upss. No se pudo conectar con el servidor, valide su conexion a internet. E intentelo de nuevo",
        });
        handleShow();
      });
  };

  if (getcustomer() !== 0) {
    history("/notice");
  }
  return (
    <Container>
      <Row className="justify-content-center align-items-center minh-100">
        <Col sm="11" md="10" lg="5" xl="4" xxl="4">
          <div className="card card-inverse p-4">
            <div className="card-block">
              <Image src="/images/logo.jpeg" fluid></Image>
              <hr />
              <Form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup className="mt-2" hasValidation>
                  <Form.Control
                    type="email"
                    {...register("email")}
                    placeholder="Escriba su correo"
                    required
                    isInvalid={response?.code === 294}
                    isValid={response === null && response.code !== 294}
                  />
                  {response?.code === 294 && (
                    <Form.Control.Feedback type="invalid">
                      {response.data}
                    </Form.Control.Feedback>
                  )}
                </InputGroup>
                <InputGroup className="mt-2" hasValidation>
                  <Form.Control
                    type="password"
                    {...register("password")}
                    autoComplete="new-password"
                    placeholder="Escriba su contraseña"
                    required
                    isInvalid={response?.code === 293}
                    isValid={response === null && response.code !== 193}
                  />
                  {response?.code === 293 && (
                    <Form.Control.Feedback type="invalid">
                      {response.data}
                    </Form.Control.Feedback>
                  )}
                </InputGroup>
                <Button
                  type="submit"
                  className="w-100 mt-2"
                  disabled={isLoading}
                >
                  {isLoading ? "Iniciando sesión…" : "Iniciar sesión"}
                </Button>
                <Link to="recoverypassword">Recuperar Contraseña</Link>
                <p>
                  ¿No tienes una cuenta?{" "}
                  <Link to="register">Regístrate aquí</Link>
                </p>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      <ModalCustom
        show={show}
        title={"Información"}
        body={response.data}
        action={response?.code === 200 ? ir_a_login : handleClose }
        action_text={response?.code === 200 ? "Iniciar sesión" : "ACEPTAR"}
        handleClose={handleClose}
      />
    </Container>
  );
}

export default Login;
