import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Verification from "./pages/Verification";
import VerificationP from "./pages/VerificationP";
import Notice from "./pages/Notice";
import RecoveryPassword from "./pages/RecoveryPassword";
import UpdatePassword from "./pages/UpdatePassword";

function App() {
  return (
    <Router>
      <Routes>
          <Route extac path="register" element={<Register/>}>
          </Route>
          <Route extac path="verification" element={<Verification/>}>
          </Route>
          <Route extac path="verificationp" element={<VerificationP/>}>
          </Route>
          <Route extac path="recoverypassword" element={<RecoveryPassword/>}>
          </Route>
          <Route extac path="updatepassword" element={<UpdatePassword/>}>
          </Route>
          <Route path="notice/:customer" element={<Notice/>}>
          </Route>
          <Route path="notice" element={<Notice/>}>
          </Route>
          <Route path="/" element={<Login />}>
          </Route>
        </Routes>
    </Router>
  );
}

export default App;
