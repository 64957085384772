import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Spinner from "../components/Spinner";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ModalCustom from "../components/ModalCustom";

function Notice() {
  const getcustomer = () => {
    return localStorage.getItem("customer") === null
      ? 0
      : localStorage.getItem("customer");
  };

  const { customer } = useParams();

  const [notice, setNotice] = useState(null);
  const [surfing, setSurfing] = useState(null);
  const [external_resource_url, setExternal_resource_url] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingB, setIsLoadingB] = useState(false);
  const [ocutarB, setocultarB] = useState(false);
  let history = useNavigate();
  const [response, setResponse] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setIsLoading(true);
    fetch(
      process.env.REACT_APP_API_WISP +
        "payments/get_notice?id_customer=" +
        (getcustomer() === 0 ? customer : getcustomer())
    )
      .then((response) => response.json())
      .catch((error) => console.error("Error:", error))
      .then((response) => {
        console.log(response);
        setNotice(response);
        setIsLoading(false);
        setSurfing(response.active);
        setExternal_resource_url(response.external_resource_url);
      });
  }, [customer]);

  if (customer === undefined && getcustomer() === 0) {
    console.log("logout");
    history("/");
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!notice) {
    return null;
  }

  if (external_resource_url != null) {
    console.log(external_resource_url);
    window.location.replace(external_resource_url);
  }

  const mp = new window.MercadoPago(process.env.REACT_APP_TOKEN_MERCADO_PAGO, {
    locale: "es-MX",
  });

  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm="12" md="4" lg="4" xl="4" xxl="4">
          <Image src="/images/logo.png" fluid></Image>
        </Col>
        <Col
          sm="12"
          md="6"
          lg="6"
          xl="6"
          xxl="6"
          className="d-flex justify-content-center align-items-center"
        >
          <h2
            className={
              notice.status === 1 || notice.status === 3
                ? "text-success mt-auto text-center"
                : notice.status === 2
                ? "text-warning mt-auto text-center"
                : "text-danger mt-auto text-center"
            }
          >
            {notice.status_text}
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center pt-2">
        <Col sm="12" md="10" lg="10" xl="10" xxl="10">
          <h2 className="text-primary m-0 text-center">
            Estimado Cliente: {notice.name + " " + notice.lastname}
          </h2>
          <p className="m-0 text-center">{notice.status_desc}</p>
        </Col>
      </Row>
      <Row className="justify-content-center pt-2">
        <Col xs="6" sm="6" md="5" lg="5" xl="5" xxl="5">
          <p className="text-secondary m-0 text-center">
            N. de Cliente: {notice.id_customer}
          </p>
        </Col>
        <Col xs="6" sm="6" md="5" lg="5" xl="5" xxl="5">
          <p className="text-secondary m-0 text-center">
            Hoy es: {notice.curdate2}
          </p>
        </Col>
      </Row>
      {notice.status !== 3 && (
        <>
          <Row className="justify-content-center pt-2">
            <Col
              sm="12"
              md="5"
              lg="5"
              xl="5"
              xxl="5"
              className="bg-primary pt-4 pb-4"
            >
              <p className="text-center text-white m-0">
                {notice.amount+" "}
                {notice.amount===1 ? "mes" : "meses"}
                {(notice.status===4 & notice.tolerance>1) ? " + "+notice.tolerance+" dias de tolerancia" : ""}
                </p>
              <h1 className="text-center text-white m-0">${notice.total}</h1>
            </Col>
            <Col
              sm="12"
              md="5"
              lg="5"
              xl="5"
              xxl="5"
              className="bg-primary pt-4 pb-4"
            >
              <p className="text-center text-white m-0">
                {notice.status === 1 || notice.status === 2
                  ? "Su fecha limite de pago es el día:"
                  : notice.status === 4
                  ? "Su fecha limite de pago fue el día:"
                  : ""}
              </p>
              <p className="text-center text-white m-0">
                {notice.status === 1
                  ? notice.date_payment2
                  : notice.status === 2 || notice.status === 4
                  ? notice.date_payment_to2
                  : ""}
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center pt-2">
            <Col sm="12" md="10" lg="10" xl="10" xxl="10">
              {notice?.cajeros !== null && (
                <p className="m-0 text-center">
                  Le recordamos que puede realizar su pago de forma fisica en tu
                  zona {notice.name_zone} con la siguiente(s) persona(s):
                  {notice.cajeros}
                </p>
              )}
              <p className="m-0 text-center">
                Realiza tu pago con tu tarjeta,transferencia, paypal, Mercado
                Pago y en efectivo en muchos comercios participantes:
              </p>
              <div className="m-0 text-center cho-container">
                {ocutarB === false && (
                  <Button
                    type="button"
                    disabled={isLoadingB}
                    display="hidden"
                    onClick={() => insert_payment(notice)}
                  >
                    {isLoadingB ? "Generando pago…" : "Generar Pago"}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </>
      )}
      <Row className="justify-content-center pt-2">
        <Col sm="12" md="10" lg="10" xl="10" xxl="10">
          <p className="text-secondary m-0 text-center">
            *CONTRATACIONES *PROBLEMAS CON SU SERVICIO *QUEJAS *SUGERENCIAS
          </p>
          <p className="text-secondary m-0 text-center">
            Comunicate con nosotros al numero:
          </p>
          <p className="text-secondary m-0 text-center">
            <a href="tel:+529361300439">9361300439</a>
          </p>
          <p className="text-secondary m-0 text-center">
            <a href="https://wa.me/9361300439?text=¡Hola+vengo+desde+el+aviso!">
              !Contáctanos por WhatsApp¡
            </a>
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center pt-2">
        <Col sm="12" md="10" lg="10" xl="10" xxl="10">
          <p className="text-secondary m-0 text-right">
            Sistema desarrollado por Gabriel González López
          </p>
          <p className="m-0 text-right">
            <a href="mailto:ggonzalezl1234@gmail.com?Subject=Estoy%20intersado%20en%20un%20desarrollo">
              Contactar por correo
            </a>
          </p>
        </Col>
      </Row>
      <FloatingWhatsApp
        phoneNumber="9361300439"
        accountName="TELECSUR"
        avatar="/images/logo_cut.png"
        statusMessage="Usualmente responde en minutos"
        chatMessage="¡Hola! ¿te podremos ayudar en algo? En TELECSUR estamos para apoyarte."
        placeholder="Escribiendo mensaje"
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
      <ModalCustom
        show={show}
        title={"Información"}
        body={response.data}
        action={handleClose}
        action_text={"ACEPTAR"}
        handleClose={handleClose}
      />
    </Container>
  );

  function insert_payment(data) {
    setIsLoadingB(true);

    fetch(
      process.env.REACT_APP_API_WISP +
        "payments/payment_client?id_customer=" +
        (getcustomer() === 0 ? customer : getcustomer())
    )
      .then(async (response) => {
        let data = await response.json();
        console.log("Success", data);
        setResponse(data);
        setIsLoadingB(false);
        if (data.code === 200) {
          document.getElementsByClassName(".cho-container").innerHTML = "";
          mp.checkout({
            preference: {
              id: data.data,
            },
            render: {
              container: ".cho-container",
              label: "¡Pagar ahora!",
            },
          });
          setocultarB(true);
        } else {
          handleShow();
        }
      })
      .catch((error) => {
        setIsLoadingB(false);
        console.error("Error:", error);
        setResponse({
          code: 500,
          data: "Upss. No se pudo conectar con el servidor, valide su conexion a internet. E intentelo de nuevo",
        });
        handleShow();
      });
  }
}

export default Notice;
