import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaQuestionCircle } from "react-icons/fa";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import ModalCustom from "../components/ModalCustom";

const popover3 = (
  <Popover id="popover-basic3">
    <Popover.Header as="h3">Contraseña</Popover.Header>
    <Popover.Body>
      Escribe una contraseña segura que utilizaras para iniciar sesión
      posteriormente.
    </Popover.Body>
  </Popover>
);

const popover4 = (
  <Popover id="popover-basic4">
    <Popover.Header as="h3">Repetir Contraseña</Popover.Header>
    <Popover.Body>
      Escribe una contraseña nuevamente, esta debe de coincidir con la anterior.
    </Popover.Body>
  </Popover>
);

function UpdatePassword() {
  const { register, handleSubmit } = useForm();
  let history = useNavigate();
  const [response, setResponse] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [query, setQuery] = useSearchParams();
  const email = query.get("email");
  const code = query.get("code");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const ir_a_login = () => history("/");
  const ir_a_recoverypassword = () => history("/recoverypassword");

  const onSubmit = (data) => {
    if (data.password !== data.password2) {
      setResponse({ code: 199, data: "Las contraseñas no coinciden" });
      console.log(response);
    } else {
      setLoading(true);
      const requestMetadata = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      fetch(
        process.env.REACT_APP_API_WISP + "users/update_password",
        requestMetadata
      )
        .then(async (response) => {
          let data = await response.json();
          console.log("Success", data);
          setLoading(false);
          setResponse(data);
            if (data.code === 200) {
              console.log(data);
              setShow(true);
            } else if (data.code === 296) {
              handleShow();
            } else {
              setResponse({
                code: 500,
                data: "Upss. Hubo un problema en el servidor, estamos trabajando en solucionarlo. intente mas tarde por favor",
              });
              handleShow();
            }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
          setResponse({
            code: 500,
            data: "Upss. No se pudo conectar con el servidor, valide su conexion a internet. E intentelo de nuevo",
          });
          handleShow();
        });
    }
  };

  return (
    <Container>
      <Row className="justify-content-center align-items-center minh-100">
        <Col sm="11" md="10" lg="5" xl="4" xxl="4">
          <div className="card card-inverse p-4">
            <div className="card-block">
              <Image src="/images/logo.jpeg" fluid></Image>
              <hr />
              <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Control
                    type="hidden"
                    {...register("email")}
                    value={email}
                  />
                  <Form.Control
                    type="hidden"
                    {...register("code")}
                    value={code}
                  />
                <InputGroup className="mt-2" hasValidation>
                  <Form.Control
                    type="password"
                    {...register("password")}
                    autoComplete="new-password"
                    placeholder="Escriba su contraseña"
                    required
                    isInvalid={response?.code === 199}
                    isValid={response === null && response.code !== 199}
                  />
                  <OverlayTrigger
                    trigger="focus"
                    placement="left"
                    overlay={popover3}
                  >
                    <Button className="p-0" variant="outline-primary">
                      <FaQuestionCircle size={"1.8em"} />
                    </Button>
                  </OverlayTrigger>
                  {response?.code === 199 && (
                    <Form.Control.Feedback type="invalid">
                      {response.data}
                    </Form.Control.Feedback>
                  )}
                </InputGroup>
                <InputGroup className="mt-2" hasValidation>
                  <Form.Control
                    type="password"
                    {...register("password2")}
                    placeholder="Repita su contraseña"
                    required
                    isInvalid={response?.code === 199}
                    isValid={response === null && response.code !== 199}
                  />
                  <OverlayTrigger
                    trigger="focus"
                    placement="left"
                    overlay={popover4}
                  >
                    <Button className="p-0" variant="outline-primary">
                      <FaQuestionCircle size={"1.8em"} />
                    </Button>
                  </OverlayTrigger>
                  {response?.code === 199 && (
                    <Form.Control.Feedback type="invalid">
                      {response.data}
                    </Form.Control.Feedback>
                  )}
                </InputGroup>
                <Button type="submit" className="w-100 mt-2">
                  Actualizar Contraseña
                </Button>
                <p>
                  <Link to="/">Inicia sesión aquí</Link>
                </p>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      <ModalCustom
        show={show}
        title={"Información"}
        body={response.data}
        action={response?.code === 200 ? ir_a_login : ( response?.code === 296 ? ir_a_recoverypassword : handleClose) }
        action_text={response?.code === 200 ? "Iniciar sesión" : (response?.code === 296 ? "Intentarlo otra vez" : "ACEPTAR")}
        handleClose={handleClose}
      />
    </Container>
  );
}

export default UpdatePassword;
