import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ModalCustom= (modal) => {
  return (
    <Modal show={modal.show} onHide={()=>modal.handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{modal.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modal.body}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={()=>modal.action()}>
          {modal.action_text}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalCustom;
